@media screen and (max-width: 300px) {
    .countdown {
        background-color: ;
        justify-content: center;
        align-items: center;
        display: flex;
        text-align: center !important;
        background-color: transparent;
        flex-direction: row;
        font-family: 'Bodoni';
        color: #4B371C;
    }

    .days-container,
    .hours-container,
    .minutes-container,
    .seconds-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        margin-top: 15px;
        color: #4B371C;

    }

    .days,
    .hours,
    .minutes,
    .seconds {
        font-size: 40px !important;
        font-weight: bold;
        font-family: 'Mainstein';
        color: #4B371C;

    }

    .days-letter,
    .hours-letter,
    .minutes-letter,
    .seconds-letter {
        font-size: 20px;
        font-weight: bold;
        font-family: 'Bodoni';
        margin-top: 20px;
        margin-right: 18px;
        color: #4B371C;
    }
}

@media screen and (min-width: 301px) and (max-width: 768px) {
    .countdown {
        justify-content: center;
        align-items: center;
        display: flex;
        background-color: transparent;
        flex-direction: row;
        font-family: 'Bodoni';
        color: #4B371C;
    }

    .days-container,
    .hours-container,
    .minutes-container,
    .seconds-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        margin-top: 15px;
        color: #4B371C;

    }

    .days,
    .hours,
    .minutes,
    .seconds {
        font-size: 50px;
        font-weight: bold;
        font-family: 'Mainstein';
        color: #4B371C;

    }

    .days-letter,
    .hours-letter,
    .minutes-letter,
    .seconds-letter {
        font-size: 20px;
        font-weight: bold;
        font-family: 'Bodoni';
        margin-top: 20px;
        margin-right: 20px;
        color: #4B371C;
    }


}

@media screen and (min-width: 769px) {
    .countdown {
        justify-content: center;
        align-items: center;
        display: flex;
        background-color: transparent;
        flex-direction: row;
        font-family: 'Bodoni';
        color: #4B371C;
    }

    .days-container,
    .hours-container,
    .minutes-container,
    .seconds-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        margin-top: 85%;
        margin-right: 10px;
    }

    .days,
    .hours,
    .minutes,
    .seconds {
        color: white;
        font-size: 80px;
        font-weight: bold;
        font-family: 'Mainstein';
    }

    .days-letter,
    .hours-letter,
    .minutes-letter,
    .seconds-letter {
        color: white;
        font-size: 40px;
        font-weight: bold;
        font-family: 'Bodoni';
        margin-top: 30px;
        margin-right: 30px;
    }
}

@font-face {
    font-family: 'Mainstein';
    src: url(../../../public/fonts/Manstein.ttf);
}

@font-face {
    font-family: 'Bodoni';
    src: url(../../../public/fonts/bodoni-72-smallcaps-book.ttf);
}