@media screen and (min-width: 769px) {
    .save-the-date {
        background: url(../../../public/images/YE-14.jpg) no-repeat;
        background-size: cover;
        background-position: center;
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .save-the-date-Logo {
        display: flex;
        position:absolute;
        top: 0;
    }

    .save-the-date-titre {
        display: flex;
        font-family: 'Bonodi';
        font-size: 80px;
        color: #4B371C;
    }

    .save-the-date-Nom {
        font-family: 'Aston Script';
        font-size: 70px;
        color: #4B371C;
        padding-top: 100px
    }
}

@media screen and (min-width: 301px) and (max-width: 768px) {
    .save-the-date {
        background: url(../../../public/images/YE-94.jpg) no-repeat;
        background-size: cover;
        background-position: center;
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .save-the-date-Logo {
        display: flex;
        position:absolute;
        top: 0;
    }

    .save-the-date-titre {
        display: flex;
        font-family: 'Bonodi';
        font-size: 50px;
        color: #4B371C;
        margin-top: -300px;
    }

    .save-the-date-Nom {
        font-family: 'Aston Script';
        font-size: 38px;
        color: #4B371C;
    }
}

@media screen and (max-width: 300px){
    .save-the-date {
        background: url(../../../public/images/YE-94.jpg) no-repeat;
        background-size: cover;
        background-position: center;
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .save-the-date-Logo {
        display: flex;
        position:absolute;
        top: 0;
    }

    .save-the-date-titre {
        display: flex;
        font-family: 'Bonodi';
        font-size: 50px;
        color: #4B371C;
        margin-top: -230px;
    }

    .save-the-date-Nom {
        font-family: 'Aston Script';
        font-size: 30px;
        color: #4B371C;
    }
}

@font-face {
    font-family: 'Aston Script';
    src: url(../../../public/fonts/Aston-Script.ttf);
}

@font-face {
    font-family: 'Bodoni';
    src: url(../../../public/fonts/bodoni-72-smallcaps-book.ttf);
}