@media screen and (max-width: 768px) {
    .countdown2 {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        flex-direction: row;
        font-family: 'Bodoni';
        color: #4B371C;
    }

    .days-container2,
    .hours-container2,
    .minutes-container2,
    .seconds-container2 {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        color: #4B371C;
    }

    .days2,
    .hours2,
    .minutes2,
    .seconds2 {
        font-size: 40px;
        font-weight: bold;
        font-family: 'Mainstein';
        margin-right: 3px;
        color: #4B371C;
    }

    .days-letter2,
    .hours-letter2,
    .minutes-letter2,
    .seconds-letter2 {
        color: #4B371C;
        font-size: 20px;
        font-weight: bold;
        font-family: 'Bodoni';
        margin-top: 20px;
        margin-right: 20px;
    }
}

@media screen and (min-width: 769px) {
    .countdown2 {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        flex-direction: row;
        font-family: 'Bodoni';
        color: #4B371C;
    }

    .days-container2,
    .hours-container2,
    .minutes-container2,
    .seconds-container2 {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
    }

    .days2,
    .hours2,
    .minutes2,
    .seconds2 {
        color: #4B371C;
        font-size: 80px;
        font-weight: bold;
        font-family: 'Mainstein';
    }

    .days-letter2,
    .hours-letter2,
    .minutes-letter2,
    .seconds-letter2 {
        color: #4B371C;
        font-size: 40px;
        font-weight: bold;
        font-family: 'Bodoni';
        margin-top: 20px;
        margin-right: 30px;
    }
}

@font-face {
    font-family: 'Mainstein';
    src: url(../../../public/fonts/Manstein.ttf);
}

@font-face {
    font-family: 'Bodoni';
    src: url(../../../public/fonts/bodoni-72-smallcaps-book.ttf);
}