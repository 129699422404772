@media screen and (max-width: 768px) {
    .background-color-countdown {
        background-image: linear-gradient(90deg, rgba(179, 147, 76, 1) 0%, rgba(238, 212, 120, 1) 17%, rgba(179, 147, 76, 1) 42%, rgba(223, 197, 105, 1) 66%, rgba(179, 147, 76, 1) 85%);
    }

    .rdv {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;
        font-family: 'Bodoni';
        color: #4B371C;
        font-size: 25px;
    }

    .count-direction {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        margin-top: -15px;
    }

}

@media screen and (min-width: 769px) {
    .background-color-countdown {
        background-image: linear-gradient(90deg, rgba(179, 147, 76, 1) 0%, rgba(238, 212, 120, 1) 17%, rgba(179, 147, 76, 1) 42%, rgba(223, 197, 105, 1) 66%, rgba(179, 147, 76, 1) 85%);
    }

    .rdv {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;
        font-family: 'Bodoni';
        color: #4B371C;
        font-size: 25px;
    }

    .count-direction {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        margin-top: -15px;
    }
}

@font-face {
    font-family: 'Aston Script';
    src: url(../../../public/fonts/Aston-Script.ttf);
}

@font-face {
    font-family: 'Bodoni';
    src: url(../../../public/fonts/bodoni-72-smallcaps-book.ttf);
}