@media screen and (max-width: 768px) {
    .Houppa {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        font-family: 'Bodoni';
        margin-bottom: 20px;
        padding-bottom: 30px;
        color: #4B371C;
    }

    .titre-Houppa {
        font-size: 40px;
        color: #C79939;
        margin-top: 15px;
    }

    .fond-invitation-houppa {
        background-image: url("../../../public/images/Fond.jpg");
        background-size: cover;
    }
}

@media screen and (min-width: 769px) {
    .Houppa {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        font-family: 'Bodoni';
        margin-bottom: 20px;
        padding-bottom: 30px;
        color: #4B371C;
    }

    .titre-Houppa {
        font-size: 60px;
        color: #C79939;
        margin-top: 15px;
    }

    .fond-invitation-houppa {
        background-image: url("../../../public/images/Fond.jpg");
        background-size: cover;
        width: 100%;
    }
}

@font-face {
    font-family: 'Aston Script';
    src: url(../../../public/fonts/Aston-Script.ttf);
}

@font-face {
    font-family: 'Bodoni';
    src: url(../../../public/fonts/bodoni-72-smallcaps-book.ttf);
}

.active.fade-bottom {
    animation: fade-bottom 1s ease-in;
}

.active.fade-left {
    animation: fade-left 1s ease-in;
}

.active.fade-right {
    animation: fade-right 1s ease-in;
}

@keyframes fade-bottom {
    0% {
        transform: translateY(50px);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes fade-left {
    0% {
        transform: translateX(-100px);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes fade-right {
    0% {
        transform: translateX(100px);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}