@media screen and (max-width: 768px) {

    .fond-invitation-reponse {
        background-image: url("../../../public/images/Fond.jpg");
        background-size: cover;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
        margin: 10px;
        border: none;
    }

    .rsvp-form-container {
        max-width: 600px;
        margin: auto;
        padding: 20px;
        font-family: Arial, sans-serif;
        color: #4B371C;

    }

    .rsvp-form {
        display: flex;
        flex-direction: column;
        color: #4B371C;
    }

    .form-label {
        margin-bottom: 10px;
        font-weight: bold;
        font-size: 15px;
        color: #4B371C;
    }

    .form-input,
    .form-textarea {
        width: 100%;
        padding: 8px;
        margin-bottom: 20px;
        border: 1px solid #ccc;
        border-radius: 4px;
    }

    .radio-group {
        margin-bottom: 20px;
    }

    .radio-label {
        display: flex;
        margin-bottom: 5px;
        text-align: flex-start;
        color: #4B371C;
    }

    .form-button {
        background-color: #C79939;
        color: white;
        padding: 10px 20px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }

    .form-button:hover {
        background-color: #C79939;
    }

}

@media screen and (min-width: 769px) {

    .fond-invitation-reponse {
        background-image: url("../../../public/images/Fond.jpg");
        background-size: cover;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
        margin: 10px;
        border: none;
    }

    .rsvp-form-container {
        max-width: 600px;
        margin-top: 40px;
        margin-bottom: 40px;
        margin-left: 150px;
        margin-right: 150px;
        padding: 20px;
        font-family: Arial, sans-serif;
        color: #4B371C;
    }

    .rsvp-form {
        display: flex;
        flex-direction: column;
        color: #4B371C;
    }
    
    .form-label {
        margin-bottom: 10px;
        font-weight: bold;
        color: #4B371C;
    }

    .form-input,
    .form-textarea {
        width: 100%;
        padding: 8px;
        margin-bottom: 20px;
        border: 1px solid #ccc;
        border-radius: 4px;
        
    }

    .radio-group {
        margin-bottom: 20px;
    }

    .radio-label {
        display: flex;
        margin-bottom: 5px;
        text-align: flex-start;
        color: #4B371C;
    }

    .form-button {
        background-color: #C79939;
        color: white;
        padding: 10px 20px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }

    .form-button:hover {
        background-color: #C79939;
    }

}

@font-face {
    font-family: 'Aston Script';
    src: url(../../../public/fonts/Aston-Script.ttf);
}

@font-face {
    font-family: 'Bodoni';
    src: url(../../../public/fonts/bodoni-72-smallcaps-book.ttf);
}


.active.fade-bottom {
    animation: fade-bottom 1s ease-in;
}

.active.fade-left {
    animation: fade-left 1s ease-in;
}

.active.fade-right {
    animation: fade-right 1s ease-in;
}

@keyframes fade-bottom {
    0% {
        transform: translateY(50px);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes fade-left {
    0% {
        transform: translateX(-100px);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes fade-right {
    0% {
        transform: translateX(100px);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}