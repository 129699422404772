@media screen and (max-width: 768px) {
  .background {
    background-image: url("../../../public/images/YE-94.jpg");
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100vh;
    display: flex;
  }

  .corp {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .titre {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: -8%;
    width: 100%;
    height: 25%;
  }

  .titre-Acceuil {
    font-family: 'Bodoni';
    font-size: 50px;
    color: #4B371C;
    margin-top: 40px;
  }

  .titre-Nom {
    font-family: 'Aston Script';
    font-size: 28px;
    color: #4B371C;
  }

  .countdown-main {
    display: flex;
    flex-direction: row;
    height: 50%;
  }

  .empty {
    display: none;
    width: 50%;
  }

  .empty2 {
    display: none;
    width: 50%;
  }

  .countdown {
    width: 100%;
    color: #4B371C;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30%;
  }

  .lien {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    font-family: 'Bodoni';
    background-color: transparent;
    color: #4B371C;    
  }

  .lien-button {
    .btn-primary {
      background-color: transparent;
      border: 2px solid white;
      color: white;
      font-size: 20px;
      padding: 10px 20px;
      margin: 10px;
      border: none;
      color: #4B371C;
    }
  }

}

@media screen and (min-width: 769px) {
  .background {
    background-image: url("../../../public/images/YE-14.jpg");
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100vh;
    display: flex;
  }

  .corp {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  .titre {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 33%;
    color: #4B371C;
  }

  .titre-Acceuil {
    font-family: 'Bodoni';
    font-size: 50px;
    color: #4B371C;
  }

  .titre-Nom {
    font-family: 'Aston Script';
    font-size: 40px;
    color: #4B371C;
  }

  .countdown-main {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: row;
    margin-left: 250px;
    margin-bottom: 150px;
    height: 50%;
  }

  .empty {
    display: none;
    width: 50%;
  }

  .empty2 {
    display: none;
    width: 50%;
  }

  .countdown {
    width: 50%;
    height: 33%;
  }

  .lien {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    font-family: 'Bodoni';
    background-color: transparent;
    height: 33%;
  }

  .bouton-log {
    color: #4B371C;
    background-color: transparent;
    border-color: #4B371C;
    border-radius: 10px;
    font-size: 25px;
  }

  .lien-button {
    .btn-primary {
      background-color: transparent;
      border: 2px solid #4B371C;
      font-size: 20px;
      padding: 10px 20px;
      margin: 10px;
      border: none;
    }
  }
}

  @font-face {
    font-family: 'Aston Script';
    src: url(../../../public/fonts/Aston-Script.ttf);
  }

  @font-face {
    font-family: 'Bodoni';
    src: url(../../../public/fonts/bodoni-72-smallcaps-book.ttf);
  }