@media screen and (min-width: 769px) {
    .footer {
        display: flex;
        background-image: linear-gradient(90deg, rgba(179, 147, 76, 1) 0%, rgba(238, 212, 120, 1) 17%, rgba(179, 147, 76, 1) 42%, rgba(223, 197, 105, 1) 66%, rgba(179, 147, 76, 1) 85%);
        flex-direction: line;
        align-items: center;
        text-align: center;
        font-family: 'Bodoni';
    }

    .footer-logo {
        width: 33%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .footer-logo img {
        margin-top: 3%;
        margin-bottom: 3%;
        width: 8%;
    }

    .footer-event {
        width: 33%;
    }

    .footer-event p {
        margin-top: 3%;
        margin-bottom: 3%;
        font-size: 15px;
        font-weight: bold;
        color: black;
    }

    .footer-link {
        width: 33%;
        margin-left: 0.5%;
        margin-right: 0.5%;
        text-decoration: none;
    }

    .footer-link p {
        margin-top: 3%;
        margin-bottom: 3%;
        font-size: 15px;
        font-weight: bold;
        color: black;
    }

    .footer-link a {
        text-decoration: none;
        color: black;
    }

}


@media screen and (max-width : 768px) {
    .footer {
        display: flex;
        flex-direction: row-reverse;
        background-image: linear-gradient(90deg, rgba(179, 147, 76, 1) 0%, rgba(238, 212, 120, 1) 17%, rgba(179, 147, 76, 1) 42%, rgba(223, 197, 105, 1) 66%, rgba(179, 147, 76, 1) 85%);
        bottom: 0;
        width: 100%;
        height: 100%;
        font-family: 'Bodoni';
    }

    .footer-event {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 33%;
    }

    .footer-event p {
        margin-top: 3%;
        margin-bottom: 3%;
        font-size: 10px;
        padding-right: 15px;
        font-weight: bold;
        color: black;
    }

    .footer-logo {
        display: flex;
        justify-content: center;
        width: 34%;
    }

    .footer-logo img {
        width: 25%;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .footer-link {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 33%;
        text-decoration: none;
        color: black;
    }

    .footer-link p {
        margin-top: 3%;
        margin-bottom: 3%;
        font-size: 10px;
        font-weight: bold;
        text-decoration: none;
        color: black;
    }

    .footer-link a {
        text-decoration: none;
        color: black;
    }
}


@font-face {
    font-family: 'Aston Script';
    src: url(../../../public/fonts/Aston-Script.ttf);
}

@font-face {
    font-family: 'Bodoni';
    src: url(../../../public/fonts/bodoni-72-smallcaps-book.ttf);
}