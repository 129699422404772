@media screen and (max-width: 300px) {
    .fond-invitation-mairie {
        background-image: url("../../../public/images/Fond.jpg");
        background-size: cover;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
        margin: 10px;
        border: none;
    }

    .carte-mairie {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 80%;
        margin: 15px;
        color: #4B371C;
    }

    .prenom {
        color: #C79939;
        text-align: center;
        font-family: 'Aston Script';
        font-size: 20px;
        padding: 10px;
    }

    .info-mairie {
        display: flex;
        font-size: 20px;
        padding: 8px;
        color: #4B371C;
    }

    .oui {
        color: #C79939;
        font-size: 20px;
        padding: 10px;
    }

    .info-mairie2 {
        display: flex;
        font-size: 15px;
        padding: 8px;
        text-align: center;
        color: #4B371C;
    }

    .button-itineraire {
        background-color: transparent;  
        color: #4B371C;
        font-size: 20px;
        font-weight: bold;
        margin-top: 5px;
        border: 1px solid #4B371C;
        border-radius: 10px;
    }
}

@media screen and (min-width: 301px) and (max-width: 768px) {
    .fond-invitation-mairie {
        background-image: url("../../../public/images/Fond.jpg");
        background-size: cover;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
        margin: 10px;
        border: none;
    }

    .carte-mairie {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 80%;
        margin: 15px;
        color: #4B371C;
    }

    .prenom {
        color: #C79939;
        text-align: center;
        font-family: 'Aston Script';
        font-size: 24px;
        padding: 10px;
    }

    .info-mairie {
        display: flex;
        font-size: 20px;
        padding: 8px;
        color: #4B371C;

    }

    .oui {
        color: #C79939;
        font-size: 30px;
        padding: 10px;
    }

    .info-mairie2 {
        display: flex;
        font-size: 15px;
        padding: 8px;
        text-align: center;
        color: #4B371C;
    }

    .button-itineraire {
        background-color: transparent;  
        color: #4B371C;
        font-size: 20px;
        font-weight: bold;
        margin-top: 5px;
        border: 1px solid #4B371C;
        border-radius: 10px;
    }
}

@media screen and (min-width: 769px) {
    .fond-invitation-mairie {
        background-image: url("../../../public/images/Fond.jpg");
        background-size: cover;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
        margin: 10px;
        border: none;
    }

    .carte-mairie {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 80%;
        margin-top: 40px;
        margin-bottom: 40px;
        margin-left: 150px;
        margin-right: 150px;
        color: #4B371C;
    }

    .prenom {
        color: #C79939;
        text-align: center;
        font-family: 'Aston Script';
        font-size: 36px;
        padding: 10px;
    }

    .info-mairie {
        display: flex;
        font-size: 30px;
        padding: 8px;
        color: #4B371C;
    }

    .oui {
        color: #C79939;
        font-size: 45px;
        padding: 10px;
    }

    .info-mairie2 {
        display: flex;
        font-size: 22px;
        padding: 8px;
        text-align: center;
        color: #4B371C;
    }

    .button-itineraire {
        background-color: transparent;  
        color: #4B371C;
        font-size: 20px;
        font-weight: bold;
        margin-top: 10px;
        border: 1px solid #4B371C;
        border-radius: 10px;
    }
}

@font-face {
    font-family: 'Aston Script';
    src: url(../../../public/fonts/Aston-Script.ttf);
}

@font-face {
    font-family: 'Bodoni';
    src: url(../../../public/fonts/bodoni-72-smallcaps-book.ttf);
}

.reveal {
    position: relative;
    opacity: 0;
}

.reveal.active {
    opacity: 1;
}

.active.fade-bottom {
    animation: fade-bottom 1s ease-in;
}

.active.fade-left {
    animation: fade-left 1s ease-in;
}

.active.fade-right {
    animation: fade-right 1s ease-in;
}

@keyframes fade-bottom {
    0% {
        transform: translateY(50px);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes fade-left {
    0% {
        transform: translateX(-100px);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes fade-right {
    0% {
        transform: translateX(100px);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}
  