@media screen and (max-width: 300px) {

    .corps {
        display: flex;
        margin-top: 30px;
        height: 120px;
        margin-bottom: -80px;
    }

    .carte-houppa {
        font-family: 'Bodoni';
        color: #4B371C;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .en-tete-carte {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin-top: 20px;
        margin-bottom: 20px;
        color: #4B371C;
    }

    .famille1 {
        display: flex;
        position: start;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        text-align: left;
        margin-left: 10px;
        width: 100%;
        font-size: 10px;
        color: #4B371C;
    }

    .famille2 {
        display: flex;
        position: end;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        text-align: right;
        margin-right: 10px;
        width: 100%;
        font-size: 10px;
        color: #4B371C;
    }

    .info-houppa {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 100%;
        margin-top: 10px;
        margin-bottom: 10px;
        padding-right: 20px;
        padding-left: 20px;
        font-size: 15px;
        color: #4B371C;
    }

    .nom-houppa {
        font-family: 'Aston Script';
        font-size: 30px;
        color: #C79939;
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .nom-houppa-hebreu {
        font-family: 'Aston Script';
        font-size: 30px;
        color: #C79939;
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .info-houppa-salle {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 100%;
        margin-top: 10px;
        margin-bottom: 10px;
        padding-right: 20px;
        padding-left: 20px;
        font-size: 20px;
        color: #4B371C;
    }

    .info-houppa-salle-hebreu {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 100%;
        margin-top: 10px;
        margin-bottom: 10px;
        padding-right: 20px;
        padding-left: 20px;
        font-size: 20px;
        color: #4B371C;
    }

    .button-itineraire-houppa {
        background-color: transparent;
        color: black;
        font-size: 20px;
        font-weight: bold;
        margin-top: 5px;
        margin-bottom: 5px;
        border: 1px solid #4B371C;
        border-radius: 10px;
        color: #4B371C;
    }
}

@media screen and (min-width: 300px) and (max-width: 768px) {
    .corps {
        display: flex;
        margin-top: 30px;
        height: 120px;
        margin-bottom: -80px;
    }

    .carte-houppa {
        font-family: 'Bodoni';
        color: #4B371C;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .en-tete-carte {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin-top: 20px;
        margin-bottom: 20px;
        color: #4B371C;
    }

    .famille1 {
        display: flex;
        position: start;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-left: 10px;
        padding-right: 15px;
        text-align: left;
        width: 100%;
        font-size: 12px;
        color: #4B371C;
    }

    .famille2 {
        display: flex;
        position: end;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        text-align: right;
        margin-right: 10px;
        width: 100%;
        font-size: 12px;
        color: #4B371C;
    }

    .info-houppa {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 100%;
        margin-top: 10px;
        margin-bottom: 10px;
        padding-right: 20px;
        padding-left: 20px;
        font-size: 15px;
        color: #4B371C;
    }

    .nom-houppa {
        font-family: 'Aston Script';
        font-size: 30px;
        color: #C79939;
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .nom-houppa-hebreu {
        font-family: 'Aston Script';
        font-size: 30px;
        color: #C79939;
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .info-houppa-salle {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 100%;
        margin-top: 10px;
        margin-bottom: 10px;
        padding-right: 20px;
        padding-left: 20px;
        font-size: 20px;
        color: #4B371C;
    }

    .info-houppa-salle-hebreu {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 100%;
        margin-top: 10px;
        margin-bottom: 10px;
        padding-right: 20px;
        padding-left: 20px;
        font-size: 20px;
        color: #4B371C;
    }

    .button-itineraire-houppa {
        background-color: transparent;
        color: black;
        font-size: 20px;
        font-weight: bold;
        margin-top: 5px;
        margin-bottom: 5px;
        border: 1px solid #4B371C;
        border-radius: 10px;
        color: #4B371C;
    }
}

@media screen and (min-width: 769px) {
    .corps {
        display: flex;
        margin-top: 30px;
        height: 300px;
        margin-bottom: -150px;
    }

    .carte-houppa {
        font-family: 'Bodoni';
        color: #4B371C;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .en-tete-carte {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin-bottom: 20px;
        justify-content: center;
        align-items: center;
        color: #4B371C;
    }

    .famille1 {
        display: flex;
        position: start;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-left: 10px;
        width: 100%;
        font-size: 18px;
        color: #4B371C;
    }

    .famille2 {
        display: flex;
        position: end;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        margin-right: 10px;
        width: 100%;
        font-size: 18px;
        color: #4B371C;
    }

    .info-houppa {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 100%;
        margin-top: 10px;
        margin-bottom: 10px;
        padding-right: 20px;
        padding-left: 20px;
        font-size: 22px;
        color: #4B371C;

    }

    .nom-houppa {
        font-family: 'Aston Script';
        font-size: 45px;
        color: #C79939;
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .nom-houppa-hebreu {
        font-family: 'Aston Script';
        font-size: 45px;
        color: #C79939;
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .info-houppa-salle {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 100%;
        margin-top: 10px;
        margin-bottom: 10px;
        padding-right: 20px;
        padding-left: 20px;
        font-size: 30px;
        color: #4B371C;
    }

    .info-houppa-salle-hebreu {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 100%;
        margin-top: 10px;
        margin-bottom: 10px;
        padding-right: 20px;
        padding-left: 20px;
        font-size: 30px;
        color: #4B371C;
    }

    .button-itineraire-houppa {
        background-color: transparent;
        color: #4B371C;
        height: 40px;
        font-size: 20px;
        font-weight: bold;
        border: 1px solid #4B371C;
        border-radius: 10px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

@font-face {
    font-family: 'Aston Script';
    src: url(../../../public/fonts/Aston-Script.ttf);
}

@font-face {
    font-family: 'Bodoni';
    src: url(../../../public/fonts/bodoni-72-smallcaps-book.ttf);
}


.active.fade-bottom {
    animation: fade-bottom 1s ease-in;
}

.active.fade-left {
    animation: fade-left 1s ease-in;
}

.active.fade-right {
    animation: fade-right 1s ease-in;
}

@keyframes fade-bottom {
    0% {
        transform: translateY(50px);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes fade-left {
    0% {
        transform: translateX(-100px);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes fade-right {
    0% {
        transform: translateX(100px);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}