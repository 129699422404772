
@media screen and (max-width: 768px){
    .my-navbar {
        background-color: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media screen and (min-width: 769px){
    .my-navbar {
        background-color: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}